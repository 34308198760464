<template>
  <Layout class="profile-page">
    <PageWrapper>
      <section class="profile-data">
        <Avatar />
        <h2>{{ user.displayName }}</h2>
        <button class="btn-solid" @click="logout">logout</button>
      </section>
    </PageWrapper>
  </Layout>
</template>

<script>
import { mapState } from 'vuex'
import Avatar from '@/components/Avatar/Avatar'
import Layout from '@/components/Layout/Layout'
import PageWrapper from '@/components/PageWrapper/PageWrapper'

export default {
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newUser) {
      if (!newUser) {
        this.$router.push({ name: 'Home' })
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
  },
  components: {
    Avatar,
    Layout,
    PageWrapper,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.profile-page {
  .profile-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 2rem 0;
    color: $primaryColor;
  }

  .avatar {
    margin-bottom: 1rem;
  }
}
</style>
